import {
	createLengthValidator,
	createRequiredValidator,
	formValidation,
} from './validate'

const forms = document.querySelectorAll('form[data-dispatch-login]')

const { validateInput, validateForm } = formValidation({
	username: [
		createRequiredValidator(),
		createLengthValidator({ max: 32 }),
	],
	password: [
		createRequiredValidator(),
		createLengthValidator({ max: 32 }),
	],
})

const getRequestId = (() => {
		let id = 0
		return () => id++
})()

async function onSubmit(event) {
	event.preventDefault()

	const form = event.target
	const isValid = validateForm(form)
	if (!isValid) return

	const formAction = form.getAttribute('action')
	const fetchUrl = formAction.endsWith('/')
		? formAction.slice(0, formAction.length - 1)
		: formAction

	const formData = {}

	const formInputList = form.querySelectorAll('input')

	formInputList.forEach((input) => {
		input.setAttribute('disabled', true)

		if (input.type === 'submit') return
		const value = (() => {
			switch(input.type) {
				case 'text':
				case 'password': return input.value.trim()
				case 'checkbox': return input.checked
			}
		})()
		formData[input.name] = value
	})

	try {
		const response = await fetch(`${fetchUrl}/rpc`, {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				id: getRequestId(),
				jsonrpc: '2.0',
				buildVersion: '*',
				method: 'api1.login',
				params: [formData.username, formData.password, false]
			})
		})

		const { result, error } = await response.json()

		const hashList = []

		if (result) {
			hashList.push(`sessionId=${result}`)
		} else {
			hashList.push(`login=${encodeURIComponent(formData.username)}`)
			hashList.push(`error=${encodeURIComponent(JSON.stringify(error))}`)
		}
		window.location.href = `${formAction}#${hashList.join('&')}`
	} catch(error) {
		const errorContainer = form.querySelector('[data-dispatch-error]')
		if (errorContainer) {
			errorContainer.innerText = 'Сервер не доступен'
		} else {
			console.error(error)
		}
	} finally {
		formInputList.forEach((input) => input.removeAttribute('disabled'))
	}
}

forms.forEach((form) => {
	form.addEventListener('submit', onSubmit)

	form.querySelectorAll('input').forEach((input) => {
		if (input.type === 'submit') return
		input.addEventListener('change', ({ target }) => validateInput(target))
	})
})
